import state from './FeatureFlagsState';
import actions from './FeatureFlagsActions';
import mutations from './FeatureFlagsMutations';
import getters from './FeatureFlagsGetters';

// Namespaced Vuex module which contains its
// own state, actions, getters, mutations.
export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
};
