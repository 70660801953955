import {
  getOperatorLoyaltyFeatureFlags,
  adminGetOperatorLoyaltyFeatureFlags
} from '@/api/FeatureFlags';
import { isSuperAdmin } from '@/helpers/superAdminHelper';
import { getCopyStakeFeatureFlagItems } from '@/api/CopyStake/FeatureFlags';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import type { AxiosError } from 'axios';
import type { ActionContext } from 'vuex';
import type { FeatureFlagsState } from './FeatureFlagsState';
import {
  ICopyStakeFeatureFlagItem,
  ILoyaltyFeatureFlagItem
} from '@/api/schema';

/**
 * Actions are used to commit state mutations
 * and can contain asynchronous operations.
 * @category FeatureFlags.
 */

export default {
  /**
   * Action used to get all feature flags data (copystake + loyalty).
   * @param vuex Action Context.
   */
  async getOperatorAllFeatureFlags({
    dispatch
  }: ActionContext<FeatureFlagsState, unknown>): Promise<void> {
    try {
      await Promise.allSettled([
        dispatch('getOperatorLoyaltyFeatureFlags'),
        dispatch('getOperatorCopyStakeFeatureFlags')
      ]);
    } catch (err) {
      errorToastMessage(err as AxiosError);
    }
  },

  /**
   * Action used to get loyalty feature flags data.
   * @param vuex Action Context.
   */
  getOperatorLoyaltyFeatureFlags({
    commit
  }: ActionContext<FeatureFlagsState, unknown>): void {
    commit('setLoyaltyFeaturesLoadingStatus', true);

    const method = isSuperAdmin
      ? adminGetOperatorLoyaltyFeatureFlags
      : getOperatorLoyaltyFeatureFlags;

    method()
      .then((data: ILoyaltyFeatureFlagItem[]): void => {
        commit('setLoyaltyFeatureFlagsData', data);
      })
      .catch((err: AxiosError): void => {
        commit('setLoyaltyFeatureFlagsData', []);
        errorToastMessage(err);
      })
      .finally((): void => {
        commit('setLoyaltyFeaturesLoadingStatus', false);
      });
  },

  /**
   * Action used to get copystake feature flags data.
   * @param vuex Action Context.
   */
  getOperatorCopyStakeFeatureFlags({
    commit
  }: ActionContext<FeatureFlagsState, unknown>): void {
    commit('setCopyStakeFeaturesLoadingStatus', true);

    getCopyStakeFeatureFlagItems()
      .then((data: ICopyStakeFeatureFlagItem[]): void => {
        commit('setCopyStakeFeatureFlagsData', data);
      })
      .catch((err: AxiosError): void => {
        commit('setCopyStakeFeatureFlagsData', []);
        errorToastMessage(err);
      })
      .finally((): void => {
        commit('setCopyStakeFeaturesLoadingStatus', false);
      });
  }
};
