import type { BlockchainWalletState } from './BlockchainWalletState';
import type {
  MonthlyBuybacksStats,
  BlockchainTransactions,
  BlockchainWallet,
  BlockchainTransactionsSummary
} from '@/api/schema';
import { LoadingStatusPayload } from '@/api/schema';

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category TradingWallet
 */

export default {
  /**
   * Set trading Wallet.
   * @param state TradingWalletState.
   * @param data Trading wallet data.
   */
  setWallet(state: BlockchainWalletState, data: BlockchainWallet): void {
    state.wallets = data.wallets;
  },

  /**
   * Set stats.
   * @param state TradingWalletState.
   * @param data Buyback stats.
   */
  setStats(state: BlockchainWalletState, data: MonthlyBuybacksStats): void {
    state.stats = data;
  },

  /**
   * Set transactions.
   * @param state TradingWalletState.
   * @param data Transactions.
   */
  setTransactions(
    state: BlockchainWalletState,
    data: { type: string; transactions: BlockchainTransactions }
  ): void {
    state.transactions[data.type] = data.transactions;
  },

  /**
   * Set transactions summary.
   * @param state TradingWalletState.
   * @param data Transactions summary.
   */
  setTransactionsSummary(
    state: BlockchainWalletState,
    data: { type: string; summary: BlockchainTransactionsSummary }
  ): void {
    state.summary[data.type] = data.summary;
  },

  /**
   * Set loading status
   * @param state TradingWalletState
   * @param payload LoadingStatusPayload
   */
  setLoadingState(
    state: BlockchainWalletState,
    payload: LoadingStatusPayload
  ): void {
    state.loading[payload.key] = payload.status;
  },

  /**
   * Set buyback first date.
   * @param state wallets state.
   * @param date buyback first date.
   */
  setFirstBuybackDate(state: BlockchainWalletState, date: string | null): void {
    state.firstDate = date;
  }
};
