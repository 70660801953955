import type {
  ILoyaltyFeatureFlagItem,
  ICopyStakeFeatureFlagItem
} from '@/api/schema';
import {
  namesLoyaltyFeatureFlags,
  namesCopyStakeFeatureFlags
} from '@/store/modules/feature-flags/FeatureFlagNames';

export const DEFAULT_FEATURE_FLAG_PARAMS = {
  enabled: false,
  adminVisible: false
};

const defaultFeatureFlags = (featureFlagNames: string[]) => {
  return featureFlagNames.map((name) => ({
    name,
    ...DEFAULT_FEATURE_FLAG_PARAMS
  }));
};

/**
 * The initial state for feature flags.
 * @category FeatureFlags.
 */
export default {
  loyaltyFeatures: [...defaultFeatureFlags(namesLoyaltyFeatureFlags)],
  copyStakeFeatures: [...defaultFeatureFlags(namesCopyStakeFeatureFlags)],
  loadingLoyaltyFeatures: true,
  loadingCopyStakeFeatures: true
};

/**
 * Describes feature management state.
 * @category FeatureFlags.
 */
export interface FeatureFlagsState {
  loyaltyFeatures: ILoyaltyFeatureFlagItem[];
  copyStakeFeatures: ICopyStakeFeatureFlagItem[];
  loadingLoyaltyFeatures: boolean;
  loadingCopyStakeFeatures: boolean;
}
