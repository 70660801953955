/**
 * The initial state for Dashboard.
 * @category  Dashboard.
 */
import {
  AvgUsersStatsItem,
  AvgNgrStatsItem,
  OperatorWalletItem
} from '@/api/schema';

export default {
  ggr: {
    day: { amount: 0, amountRation: 0 },
    month: { amount: 0, amountRation: 0 }
  },
  accountBalance: 0,
  accountBalanceLastWeekRatio: 0,
  walletBalances: 0,
  walletBalancesLastWeekRatio: 0,
  inStake: 0,
  inStakeLastWeekRatio: 0,
  operatorWallet: {},
  NGRData: [],
  usersStakeData: [],
  loading: {
    transfer: false,
    withdraw: false
  }
};

export interface GgrDashboard {
  day: number;
  month: number;
}

export interface OperatorWallet {
  FEE: OperatorWalletItem;
  MASTER: OperatorWalletItem;
  REWARD: OperatorWalletItem;
  STAKING: OperatorWalletItem;
  TOKEN_SALE: OperatorWalletItem;
}

export interface DashboardState {
  ggr: GgrDashboard;
  accountBalance: number;
  accountBalanceLastWeekRatio: number;
  walletBalances: number;
  walletBalancesLastWeekRatio: number;
  inStake: number;
  inStakeLastWeekRatio: number;
  operatorWallet: OperatorWallet;
  NGRData: AvgNgrStatsItem[];
  usersStakeData: AvgUsersStatsItem[];
  loading: {
    transfer: boolean;
    withdraw: boolean;
  };
}
