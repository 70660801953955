import type { OnboardingState } from './OnboardingState';
import { groupOperatorsByAccounts, Status } from '@/helpers/operatorHelpers';
import {
  EOnbordingStep,
  EOnbordingStepStatus,
  OperatorOnboardingForm,
  TOnboardingProgress,
  IAccountListItem
} from '@/api/schema';

const { COMPLETED } = EOnbordingStepStatus;
const onboardingTestSteps = Object.freeze([
  EOnbordingStep.tokenInfoProgress,
  EOnbordingStep.uiSettingsProgress,
  EOnbordingStep.inviteDevProgress,
  EOnbordingStep.whitelistProgress,
  EOnbordingStep.accountCreationProgress,
  EOnbordingStep.getUrlProgress,
  EOnbordingStep.tokenExchangeProgress,
  EOnbordingStep.trafficProgress
]);

export const checkIsTestComplete = (progress: TOnboardingProgress): boolean => {
  if (Array.isArray(progress)) return false;
  const isCompleted = (s: EOnbordingStep) => progress[s] === COMPLETED;

  return onboardingTestSteps.every(isCompleted);
};

export default {
  /**
   * Get operator env type.
   * @param state Onboarding State.
   * @returns is test env.
   */
  isTestEnv(state: OnboardingState): boolean {
    return !!state.selectedOperator?.test;
  },

  /**
   * Get operator data need for creation.
   * @param state Onboarding State.
   * @returns creation operator data.
   */
  createOperatorData(state: OnboardingState): OperatorOnboardingForm {
    return {
      ...state.onboardingData,
      ...state.newOperator
    };
  },

  /**
   * Get operator onboarding form
   * @param state onboarding state
   * @return onboarding form
   */

  onboardingForm(state: OnboardingState): OperatorOnboardingForm {
    const {
      ggr,
      name,
      test,
      tokenName,
      tokenNumber,
      tokenPrice,
      tokenTicker,
      url
    } = state.onboardingData;

    return {
      ggr,
      name,
      test,
      tokenName,
      tokenNumber,
      tokenPrice,
      tokenTicker,
      url
    };
  },

  /**
   * Get operator id.
   * @param state Onboarding State.
   * @param _
   * @param rootState
   * @return operator id.
   */
  operatorId(
    state: OnboardingState,
    _: unknown,
    rootState: {
      Auth: { operatorId: any; token: string };
      SuperAdmin: { operatorId: any };
    }
  ): number | null {
    if (!rootState.Auth.token) {
      return null;
    }

    return (
      state.selectedOperator?.id ||
      rootState.Auth.operatorId ||
      rootState.SuperAdmin.operatorId ||
      null
    );
  },

  /**
   * Get operator id.
   * @param state Onboarding State.
   * @returns test env.
   */
  envName(state: OnboardingState): string | null {
    return (
      state.selectedOperator.name || state.onboardingData.name || 'Operator'
    );
  },

  /**
   * Get current brand name.
   * @param state Onboarding State.
   * @returns current brand name.
   */
  currentBrandName(state: OnboardingState): string {
    return state.selectedOperator.brandName || 'Other';
  },

  /**
   * Get is operator approved by SuperAdmin.
   * @param state Onboarding State.
   * @returns operator approve status.
   */
  operatorApproved(state: OnboardingState): boolean {
    const { status, test } = state.selectedOperator?.id
      ? state.selectedOperator
      : state.onboardingData;

    return (
      status && !(!test && [Status.declined, Status.created].includes(status))
    );
  },

  /**
   * Show mobile app status
   */

  isMobile(state: OnboardingState): boolean {
    return state.accountInfo?.businessType === 'MOBILE_APP';
  },

  /**
   * Show email verification status
   */
  isEmailVerified(state: OnboardingState): boolean {
    return !!state.accountInfo?.emailVerified;
  },

  /**
   * Flag if operator has with reporting ("accept") integration
   */
  isReportingIntegration(state: OnboardingState): boolean {
    return state.mode?.proxyMode === 'ACCEPT';
  },

  /**
   * Flag if smart contract has been deployed
   */

  isDeployedSmartContract(state: OnboardingState): boolean {
    return state.onboardingData.deployed;
  },

  /**
   * Flag if is proxy enabled
   */
  isProxy(state: OnboardingState): boolean {
    return state.mode.proxyMode === 'PROXY';
  },

  operatorProgress(state: OnboardingState): Record<string, boolean> {
    const currentId = state.selectedOperator.id;
    const currentData = state.onboardingProgress[currentId];
    if (!currentData) return {};

    return Object.entries(currentData).reduce(
      (all, [key, val]) => ({
        ...all,
        [key]: val === COMPLETED
      }),
      {}
    );
  },

  successOnboardingTestProgress(state: OnboardingState): boolean {
    if (!state.selectedOperator.test) return false;
    const { id } = state.selectedOperator;
    const { [id]: progress } = state.onboardingProgress;

    return progress && checkIsTestComplete(progress);
  },

  operatorListByGroup(
    state: OnboardingState,
    _: { [key: string]: any },
    rootState: { [key: string]: any },
    rootGetters: { [key: string]: any }
  ): IAccountListItem[] {
    const operators = rootState.app.isSuperAdmin
      ? rootGetters['SuperAdmin/operators']
      : state.operators;

    return groupOperatorsByAccounts(operators);
  },

  /**
   * Get current account.
   * @param _ Onboarding State.
   * @param getters getters
   * @returns test env.
   */
  currentAccount(
    _: OnboardingState,
    getters: {
      currentBrandName: string;
      operatorListByGroup: IAccountListItem[];
    }
  ): IAccountListItem {
    return getters.operatorListByGroup.find(
      (account: IAccountListItem) =>
        account.name.toLowerCase() === getters.currentBrandName.toLowerCase()
    );
  }
};
