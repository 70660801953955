import { FeatureFlagsState } from './FeatureFlagsState';
import type {
  ILoyaltyFeatureFlagItem,
  ICopyStakeFeatureFlagItem
} from '@/api/schema';

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category FeatureFlags
 */

export default {
  /**
   * Set loyalty feature flags data.
   * @param state Feature Flags State.
   * @param data loyalty feature flags data.
   */
  setLoyaltyFeatureFlagsData(
    state: FeatureFlagsState,
    data: ILoyaltyFeatureFlagItem[]
  ): void {
    state.loyaltyFeatures = data;
  },

  /**
   * Set copyStake feature flags data.
   * @param state Feature Flags State.
   * @param data copyStake feature flags data.
   */
  setCopyStakeFeatureFlagsData(
    state: FeatureFlagsState,
    data: ICopyStakeFeatureFlagItem[]
  ): void {
    state.copyStakeFeatures = data;
  },

  /**
   * Set loading loyalty features status.
   * @param state Feature Flags State.
   * @param status loading status.
   */
  setLoyaltyFeaturesLoadingStatus(
    state: FeatureFlagsState,
    status: boolean
  ): void {
    state.loadingLoyaltyFeatures = status;
  },

  /**
   * Set loading copyStake features status.
   * @param state Feature Flags State.
   * @param status loading status.
   */
  setCopyStakeFeaturesLoadingStatus(
    state: FeatureFlagsState,
    status: boolean
  ): void {
    state.loadingCopyStakeFeatures = status;
  }
};
