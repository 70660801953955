/**
 * Enum for Loyalty Feature Flags.
 */
export enum ELoyaltyFeatureFlag {
  HOLD_TO_EARN = 'hold_to_earn',
  WITHDRAWAL_REQUESTS = 'withdrawal_requests',
  PROMO_MANAGEMENT = 'promo_management',
  COPY_STAKE = 'live_streamer',
  TOKEN_SALE_PRIVATE = 'tokensale_private',
  TOKEN_SALE_PUBLIC = 'tokensale_public',
  DASHBOARD_CHARTS = 'dashboard_charts',
  PAYMENT_LIMITS = 'payment_limits',
  MISSION_MANAGEMENT = 'mission_management',
  CALCULATOR = 'calculator',
  REFERRAL_PROGRAM = 'referral_program',
  TOKENS_SALE = 'tokensale',
  MOST_POPULAR_GAMES = 'most_popular_games',
  CUSTODY = 'custody',
  PARTNER_MODE = 'partner_mode',
  LEADERBOARD = 'leaderboard',
  LIQUIDITY_STAKING_TOKEN_USDT = 'liquidity_staking_token_usdt',
  SMART_CONTRACT_ADDRESS = 'smart_contract_address',
  CASINO_INCOME = 'casino_income',
  WALLET_AUTH = 'wallet_auth',
  HISTORICAL_GAME_TRAFFIC = 'historical_game_traffic',
  NEW_DESIGN = 'new_design',
  TWO_FA_ENABLED = '2fa_enabled',
  LANGUAGE_SELECT = 'language_select',
  HIDE_LOGO = 'hide_logo',
  SPLIT_DEPOSIT_DEX_TRANSACTIONS = 'split_deposit_dex_transactions',
  PLAY_TO_EARN = 'play_to_earn',
  TOKEN_BURN_OUT = 'token_burn_out',
  INVOICING = 'invoicing',
  MANUAL_TRADING_TRANSFER = 'manual_trading_transfer',
  REMOVE_TOKENS_NON_ACTIVE_USERS = 'remove_tokens_non_active_users',
  DAILY_CASHBACK_NEW_USERS = 'daily_cashback_new_users',
  WEEKLY_CASHBACK_NEW_USERS = 'weekly_cashback_new_users',
  PANCAKE_V3 = 'pancake_V3'
}

/**
 * Enum for CopyStake Feature Flags.
 */
export enum ECopyStakeFeatureFlag {
  WEB_STREAMERS = 'become_a_streamer',
  GAMIFICATION_CENTER = 'gamification_center',
  GAMIFICATION_CENTER_SIGN_UP_REWARD = 'gamification_center_sign_up_reward',
  GAMIFICATION_CENTER_WHEEL_OF_FORTUNE = 'gamification_center_wheel_of_fortune',
  GAMIFICATION_CENTER_JACKPOT = 'gamification_center_jackpot'
}

/**
 * Get the lists of feature flag names.
 */
export const namesLoyaltyFeatureFlags = Object.values(ELoyaltyFeatureFlag);
export const namesCopyStakeFeatureFlags = Object.values(ECopyStakeFeatureFlag);
