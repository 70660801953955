import { FeatureFlagsState } from '@/store/modules/feature-flags/FeatureFlagsState';
import { EFeatureFlagListType } from '@/api/schema';
import type {
  ICopyStakeFeatureFlagItem,
  ILoyaltyFeatureFlagItem
} from '@/api/schema';
import {
  ECopyStakeFeatureFlag,
  ELoyaltyFeatureFlag
} from '@/store/modules/feature-flags/FeatureFlagNames';

export default {
  /**
   * Get all feature flags (loyalty + copystake).
   * @param state Feature Flags State.
   * @returns feature flags list.
   */
  allFeatureFlags(
    state: FeatureFlagsState
  ): Array<ICopyStakeFeatureFlagItem | ILoyaltyFeatureFlagItem> {
    return [...state.loyaltyFeatures, ...state.copyStakeFeatures];
  },

  /**
   * Get feature flag by name.
   * @param state Feature Flags State.
   * @param getters
   * @returns feature flag item.
   */
  featureFlagByName:
    (state: FeatureFlagsState, getters: { [key: string]: any }) =>
    (
      name: ELoyaltyFeatureFlag | ECopyStakeFeatureFlag
    ): ICopyStakeFeatureFlagItem | ILoyaltyFeatureFlagItem => {
      return getters.allFeatureFlags.find(
        (feature: ICopyStakeFeatureFlagItem | ILoyaltyFeatureFlagItem) =>
          feature.name === name
      );
    },

  /**
   * Get feature flag ID by name.
   * @param state Feature Flags State.
   * @param getters
   * @returns feature flag id or null.
   */
  featureFlagIdByName:
    (state: FeatureFlagsState, getters: { [key: string]: any }) =>
    (
      name: ELoyaltyFeatureFlag | ECopyStakeFeatureFlag
    ): ICopyStakeFeatureFlagItem | ILoyaltyFeatureFlagItem => {
      return getters.featureFlagByName(name)?.id ?? null;
    },

  /**
   * Get is enabled status feature flag by name.
   * @param state Feature Flags State.
   * @param getters
   * @returns is enabled status feature flag item.
   */
  isEnabledFeatureFlag:
    (state: FeatureFlagsState, getters: { [key: string]: any }) =>
    (name: ELoyaltyFeatureFlag | ECopyStakeFeatureFlag): boolean => {
      return !!getters.allFeatureFlags.find(
        (feature: ICopyStakeFeatureFlagItem | ILoyaltyFeatureFlagItem) =>
          feature.name === name
      )?.enabled;
    },

  /**
   * Get is admin visible status feature flag by name.
   * @param state Feature Flags State.
   * @param getters
   * @returns is admin visible status feature flag item.
   */
  isAdminVisibleFeatureFlag:
    (state: FeatureFlagsState, getters: { [key: string]: any }) =>
    (name: ELoyaltyFeatureFlag | ECopyStakeFeatureFlag): boolean => {
      return !!getters.allFeatureFlags.find(
        (feature: ICopyStakeFeatureFlagItem | ILoyaltyFeatureFlagItem) =>
          feature.name === name
      )?.adminVisible;
    },

  /**
   * Get is admin visible status feature flag by name.
   * @param state Feature Flags State.
   * @param getters
   * @returns is admin visible status feature flag item.
   */
  hasUserFeatureFlag:
    (state: FeatureFlagsState, getters: { [key: string]: any }) =>
    (
      name: ELoyaltyFeatureFlag | ECopyStakeFeatureFlag,
      userId: string
    ): boolean => {
      const featureFlag = getters.allFeatureFlags.find(
        (feature: ICopyStakeFeatureFlagItem | ILoyaltyFeatureFlagItem) =>
          feature.name === name
      );

      if (!featureFlag) return false;

      const userIds = featureFlag.operatorUserIds;

      if (
        featureFlag.enabled &&
        featureFlag.listType === EFeatureFlagListType.WHITE_LIST &&
        userIds.length
      ) {
        return userIds.includes(userId);
      }

      return featureFlag.enabled;
    },

  /**
   * Features loading status
   * @param state Feature Flags State.
   * @returns is feature flags loading.
   */
  isFeaturesLoading(state: FeatureFlagsState): boolean {
    return state.loadingLoyaltyFeatures || state.loadingCopyStakeFeatures;
  }
};
